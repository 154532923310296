function chunk(array, size) {
    if (!Array.isArray(array)) {
        throw new TypeError('Input should be Array');
    }

    if (typeof size !== 'number') {
        throw new TypeError('Size should be a Number');
    }
    const chunked_arr = [];
    let index = 0;
    while (index < array.length) {
        chunked_arr.push(array.slice(index, size + index));
        index += size;
    }
    return chunked_arr;
}

export {chunk };