import React from 'react';
import './style.scss';
import 'keyrune';
import 'mana-font';



export const FeatureComponent = (props)=>{

    const icon = function(icon) {
        if (icon.startsWith('ms')) {
            return <i className={`ms ${props.icon} ss-foil ss-grad`}></i>
        } else {
            return <i className={`ss ${props.icon} ss-foil ss-grad`}></i>
        }
    }

    return <div className="col-lg-6">
                <div className="feature-item">
                    {/* <i className="icon-screen-smartphone text-primary"></i> */}
                    {icon(props.icon)}
                    <h3>{props.title}</h3>
                    <p className="text-muted">{props.description}</p>
                </div>
            </div>
};