import React from 'react';
import './style.scss';
import 'simple-line-icons/css/simple-line-icons.css';
import commanderPreview from '../../assets/EDHLife_Commander.png';
import 'keyrune';

import {chunk} from '../../utilities/helpers';

import {FeatureComponent} from '../FeatureComponent/FeatureComponent';


export const FeaturesComponent = (props)=>{
  const features = props.features;

  const featureComponents = chunk(features.map((feature, index ) => {
    return <FeatureComponent   key={index}
                             title={feature.title} 
                       description={feature.description}
                              icon={feature.icon}>
            </FeatureComponent>
  }),2)  

  return <section className="features" id="features">
    <div className="container">
      <div className="section-heading text-center">
        <h2>Features</h2>
        <p className="text-muted">Check out how EDH life helps you keep track of your points in a game of MTG.</p>
        <hr />
      </div>
      <div className="row">
        <div className="col-lg-5 d-md-none d-lg-block">
        <div className="device-container">
          <div className="device device-iphone-x device-spacegray">
            <div className="device-frame device-spacegrey">
            <img src={commanderPreview} className="device-content img-fluid" alt="" />
            </div>
            <div className="device-stripe"></div>
            <div className="device-header"></div>
            <div className="device-sensors"></div>
            <div className="device-btns"></div>
            <div className="device-power"></div>
          </div>
          </div>
        </div>
        <div className="col-lg-7">
          <div className="container-fluid">
            {featureComponents.map((featureRow, index) => (
                <div className="row" key={index}>
                    {featureRow}
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  </section>
};