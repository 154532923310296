import React from 'react';
import './style.scss';
import appStoreBadge from './img/app-store-badge.svg';

 export const AppDownloadComponent = (props)=>{
  return <section className="download text-center" id="download">
    <div className="container">
      <div className="row">
        <div className="col-md-8 mx-auto">
          <h2 className="section-heading">Get it now!</h2>
          <p>Download EDH Life on the App store. </p>
          <div className="badges">
            {/* TODO: Add Link to Testflight */}
            <a className="badge-link" href="https://apps.apple.com/us/app/edh-life/id1531311477"><img src={appStoreBadge} alt="" /></a>
          </div>
        </div>
      </div>
    </div>
  </section>
};