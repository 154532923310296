import React from 'react';
import './style.scss';

export const FooterComponent = (props)=>{
  return <footer>
    <div className="container">
      <p>&copy; 2020 EDH Life. All Rights Reserved.</p>
      <ul className="list-inline">
        <li className="list-inline-item">
          <a href="/disclaimer">Imprint / Disclaimer</a>
        </li>
      </ul>
    </div>
  </footer>
};