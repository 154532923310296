import React from 'react';
import './style.scss';

export const ImprintComponent = (props)=>{
    return (
    <div className="imprint">
        <div className="container h-100">
            <div className="row">
                <span>
                <h1>Imprint</h1>
                <h4>Legal Disclosure</h4>
                <p>Information in accordance with Section 5 TMG</p>
                <address>
                    Sebastian Prokesch<br/>
                    Renatastr. 32<br/>
                    80634 München<br/>
                </address>
                <br/>

                <h4>Contact:</h4>
                <p>Email: info@edh-life.com </p>
                <br/>
                
                <h4>Responsible for contents acc. to Sec. 55, para. 2 German Federal Broadcasting Agreement (RstV):</h4>
                <p>Sebastian Prokesch</p>

                <br/>
                <h2>Disclaimer</h2>

                <h4>Magic: The Gathering</h4>
                <p>Magic: The Gathering is trademarked and owned by Wizards of the Coast, Inc., a subsidiary of Hasbro, Inc.</p>
                <p>Wizards of the Coast, Magic: The Gathering, and their logos are trademarks of Wizards of the Coast LLC in the United States and other countries. © 2018 Wizards. All Rights Reserved.</p>
                <p>This application is not affiliated with, endorsed, sponsored, or specifically approved by Wizards of the Coast LLC. This application may use the trademarks and other intellectual property of Wizards of the Coast LLC, which is permitted under Wizards’ Fan Site Policy https://company.wizards.com/fancontentpolicy.</p>


                <h4>Accountability for content</h4>
                <p>The contents of our pages have been created with the utmost care. However, we cannot guarantee the contents'  accuracy, completeness or topicality. According to statutory provisions, we are furthermore responsible for our own content on these web pages. In this matter, please note that we are not obliged to monitor the transmitted or saved information of third parties, or investigate circumstances pointing to illegal activity. Our obligations to remove or block the use of information under generally applicable laws remain unaffected by this as per §§ 8 to 10 of the Telemedia Act (TMG).</p>

                <h4>Accountability for links</h4>
                <p>Responsibility for the content of external links (to web pages of third parties) lies solely with the operators of the linked pages. No violations were evident to us at the time of linking. Should any legal infringement become known to us, we will remove the respective link immediately.</p>
                
                <h4>Copyright</h4>
                <p>Our web pages and their contents are subject to German copyright law. Unless expressly permitted by law, every form of utilizing, reproducing or processing works subject to copyright protection on our web pages requires the prior consent of the respective owner of the rights. Individual reproductions of a work are only allowed for private use. The materials from these pages are copyrighted and any unauthorized use may violate copyright laws.</p>
                <i>Source: </i><a href="http://www.translate-24h.de" target="_blank" rel="noopener noreferrer">impressum generator at translate-24h.de</a> <br/><br/>
                </span>
            </div>
        </div>
      </div>
    )
  };