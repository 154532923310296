import React from 'react';
import './style.css';

export const SocialComponent = (props)=>{
  return <section className="contact" id="contact">
    <div className="container">
      <h2>We
        <i className="fa fa-heart"></i>
        new friends!</h2>
      <ul className="list-inline list-social">
        <li className="list-inline-item social-twitter">
          <a href="https://twitter.com/EdhLife">
            <i className="fa fa-twitter"></i>
          </a>
        </li>
        
      </ul>
    </div>
  </section>
};