import React, { Component, Fragment } from 'react';
import {
  BrowserRouter as Router, 
  Switch, 
  Route
} from 'react-router-dom';

import './App.scss';
import 'font-awesome/css/font-awesome.css';

import features from './features.json'

import {FooterComponent}  from './components/FooterComponent/FooterComponent';
import {SocialComponent} from './components/SocialComponent/SocialComponent';
import {FeaturesComponent} from './components/FeaturesComponent/FeaturesComponent';
import {AppDownloadComponent} from './components/AppDownloadComponent/AppDownloadComponent';
import {HeroComponent} from './components/HeroComponent/HeroComponent';
import {NavBarComponentWithRouter} from './components/NavBarComponent/NavBarComponent';
import { ImprintComponent } from './components/ImprintComponent/ImprintComponent';


class App extends Component {
  constructor(props){
    super(props);
    this.state={navBarShrink:""};
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount(){
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event){
    const nbs = window.pageYOffset>100 ? "navbar-shrink" : "";
    this.setState({navBarShrink:nbs});
  }

  render() {
    const nbs = this.state ? this.state.navBarShrink : "";
    return (
      <Router>
        <NavBarComponentWithRouter navBarShrink = {nbs} />

        <Switch>
          <Route exact path="/">
            <Home features={features}/>
          </Route>
          <Route path="/disclaimer">
            <ImprintComponent />
          </Route>
        </Switch>
        <FooterComponent />
      </Router>
    );
  }
}

function Home(props) {
  return (
    <Fragment>
      <HeroComponent />
      <FeaturesComponent features={props.features}/>
      <AppDownloadComponent />
      <SocialComponent />
    </Fragment>
  )
}


export default App;
