import React from 'react';
import './style.scss';
import 'devices.css';
import overview from '../../assets/EDHLife_Overview.png';

export const HeroComponent = (props)=>{
  return <header className="masthead">
    <div className="container h-100">
      <div className="row h-100">
        <div className="col-lg-7 my-auto">
          <div className="header-content mx-auto">
            <h1 className="md-5">EDH Life</h1>
            <p>EDH Life is a Life tracking app for the collectible card game Magic the Gathering. It's focused on the   <i className="ss ss-c17"></i> Commander format formaly known as EDH. It features a dedicated CMD-Damage mode.</p>
            <a href="#download" className="btn btn-outline btn-xl js-scroll-trigger">Download Today!</a>
          </div>
        </div>
        <div className="col-lg-5 my-auto">
          <div className="device-container">
          <div className="device device-iphone-x device-spacegray">
            <div className="device-frame device-spacegrey">
            <img src={overview} className="device-content img-fluid" alt="" />
            </div>
            <div className="device-stripe"></div>
            <div className="device-header"></div>
            <div className="device-sensors"></div>
            <div className="device-btns"></div>
            <div className="device-power"></div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </header>
};